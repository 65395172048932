<template>
  <div>
    <header>
      <img class="left-logo" :src="require('./assets/vml.jpeg')" />
      <img class="center-logo" :src="require('./assets/Logo-Euro.png')" />
      <a href="/login" @click.prevent="goToLogin">
        <img class="profile-circle" :src="require('./assets/profile-placeholder.png')" alt="Profile" />
      </a>
    </header>
    <div>
      <div class="container">
        <div class="tab-buttons">
          <span :class="{ active: currentTab === 'Leaderboard' }" @click="currentTab = 'Leaderboard'">LEADERBOARD</span>
          <span>|</span>
          <span :class="{ active: currentTab === 'Rules' }" @click="currentTab = 'Rules'">RULES</span>
        </div>
        <div class="header">
          <p style="font-size: 40px; font-weight: bold;">EURO 2024</p>
          <p style="margin-top: -10px; font-weight: bold;">TRUST YOUR GUTS, NOT THE FACTS</p>
        </div>
      </div>

      <div class="tab-content">
        <component :is="currentTabComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Rules from '@/components/Rules.vue';
import Leaderboard from '@/components/Leaderboard.vue';
import Login from '@/components/Login.vue';

export default {
  data() {
    return {
      currentTab: 'Leaderboard',
    };
  },
  computed: {
    currentTabComponent() {
      if (this.currentTab === 'Rules') {
        return 'Rules';
      } else if (this.currentTab === 'Leaderboard') {
        return 'Leaderboard';
      } else if (this.currentTab === 'Login') {
        return 'Login';
      }
    },
  },
  components: {
    Rules,
    Leaderboard,
    Login
  },
  methods: {
    goToLogin(event) {
      event.preventDefault();
      this.currentTab = 'Login';
      this.$router.push('/login');
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;600&display=swap');

body {
  margin: 0;
  
}

#app {
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 50px;
}

header {
  background-color: #000;
  color: #fff;
  height: 70px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

header .left-logo {
  height: 80px;
  padding-right: 10px;
}

header .center-logo {
  height: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

header p {
  margin: 0;
  padding-left: 10px;
}

header .profile-circle {
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

body {
  background-color: #fff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  text-align: center;
}

.header h1 {
  font-size: 2em;
  font-weight: bold;
}

.header p {
  margin: 0;
}

.rules {
  background-color: #000;
  color: #fff;
  padding: 20px;
  margin: 0px 0;
  border-radius: 8px;
}

.rules h2 {
  margin: 0 0 10px 0;
}

.rules ul {
  list-style-type: none;
  padding: 0;
}

.rules ul li {
  margin: 5px 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 0 0px rgba(255, 255, 255, 0.1);
}

.full-width-image-wrapper {
  width: 100vw;
  background-color: #fff;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -20px;
}

.full-width-image {
  width: 100%;
  height: auto;
  display: block;
}

.registration-Rules {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 00px;
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input[type='text'],
.form-group input[type='file'] {
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.table-container {
  margin-bottom: 0px;
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.table2 {
  margin: auto;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid rgba(204, 204, 204, 0);
  overflow: hidden;
}

th,
td {
  padding: 8px;
  border: none;
}

th {
  background-color: white;
  border-bottom: 1px solid #ddd;
}

td {
  text-align: center;
}

td:nth-child(2),
th:nth-child(2) {
  text-align: left;
}

tbody tr:nth-child(odd) {
  color: #000000;
}

tbody tr:nth-child(even) {
  color: #000000;
  background-color: #EEEEEE;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  overflow-y: auto;
  max-height: 90vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.user-info p {
  margin: 0;
}

.user-info .score {
  font-size: 20px;
  font-weight: bold;
}

.close {
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  background-color: black;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.modal table {
  background-color: white;
}

.modal tbody tr:nth-child(even) {
  background-color: #ffffff;
}

@media (max-width: 600px) {
  .modal-content {
    width: 95%;
    max-width: 95%;
  }

  table,
  th,
  td {
    font-size: 1.0em;
  }

  th,
  td {
    padding: 6px;
  }
}

.match-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.td_1 {
  padding-right: 10px;
  text-align: right;
  flex: 1;
  font-size: 14px;
}

.td_2,
.td_3,
.td_4 {
  text-align: center;
  flex: 0 0 30px;
}

.td_4 {
  text-align: center;
  flex: 0 0 30px;
  width: 20px;
}

.td_5 {
  padding-left: 10px;
  text-align: left;
  flex: 1;
  font-size: 14px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.small-input {
  width: 30px;
  height: 35px;
  text-align: center;
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: -26px;
  cursor: pointer;
}

.tab-buttons span {
  /* padding: 10px; */
  margin: 0 10px;
  cursor: pointer;
}

.tab-buttons span.active {
  font-weight: bold;
}
</style>

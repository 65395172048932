<template>
  <div class="container">
    <div v-if="!userData">
      <div class="form-container">
        <form @submit.prevent="login">
          <div class="form-group">
            <input placeholder="Username" type="text" id="username" v-model="username" required>
          </div>
          <div class="form-group">
            <input placeholder="Password" type="password" id="password" v-model="password" required>
          </div>
          <button type="submit" class="login-button">LOGIN</button>
        </form>
      </div>
      <div v-if="loading" class="loading-modal">
        <div class="loading-content">
          <div class="loading-spinner">loading...</div>
        </div>
      </div>
    </div>

    <div v-if="userData">
      <div>
        <div class="tabs-container">
          <div class="tabs">
            <button class="tab-button" :class="{ active: activeTab === 'group1' }" @click="changeTab('group1')">QUALIFICATION</button>
            <button class="tab-button" :class="{ active: activeTab === 'group2' }" @click="changeTab('group2')">ROUND OF 16</button>
            <button class="tab-button" :class="{ active: activeTab === 'group3' }" @click="changeTab('group3')">QUARTER-FINALS</button>
            <button class="tab-button" :class="{ active: activeTab === 'group4' }" @click="changeTab('group4')">SEMI-FINALS</button>
            <button class="tab-button" :class="{ active: activeTab === 'group5' }" @click="changeTab('group5')">FINAL</button>
          </div>
        </div>
        <div v-if="activeTab === 'group1'">
          <div class="table-container" v-for="round in totalRounds" :key="round">
            <div class="table-header">
              <p class="table-title">SCORE PREDICTION</p>
              <p class="table-subtitle">MATCH {{ round }} OF {{ totalRounds }}</p>
            </div>
            <hr class="table-divider">
            <table>
              <tbody>
                <template v-for="match in parsedMatchData" :key="match.id">
                  <tr v-if="match.round === round" class="match-row">
                    <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                    <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" disabled></td>
                    <td class="td_3">:</td>
                    <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" disabled></td>
                    <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="activeTab === 'group2' || activeTab === 'group3' || activeTab === 'group4' || activeTab === 'group5'">
          <form @submit.prevent="submitForm">
            <div class="table-container">
              <div class="table-header">
                <p class="table-title">SCORE PREDICTION</p>
              </div>
              <hr class="table-divider">
              <table>
                <tbody>
                  <div v-if="activeTab == 'group2'">
                    <!-- G2 -->
                    <template v-if="!hasGroup2(parsedMatchData)" v-for="match in matches" :key="match.id">
                      <tr v-if="isFutureMatch(match.time)" class="match-row">
                        {{ match.group }}
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA"></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB"></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>
                    <template v-if="hasGroup2(parsedMatchData)" v-for="match in parsedMatchData" :key="match.id">
                      <tr v-if="match.group == 'Group 2'" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" disabled></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" disabled></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>
                  </div>

                  <div v-if="activeTab == 'group3'">
                    <!-- G3 -->
                    <template v-if="!hasGroup3(parsedMatchData)" v-for="match in matches" :key="match.id">
                      <tr v-if="isFutureMatch(match.time)" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA"></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB"></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>

                    <template v-if="hasGroup3(parsedMatchData)" v-for="match in parsedMatchData" :key="match.id">
                      <tr v-if="match.group == 'Group 3'" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" disabled></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" disabled></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>
                  </div>

                  <div v-if="activeTab == 'group4'">
                    <!-- G4 -->
                    <template v-if="!hasGroup4(parsedMatchData)" v-for="match in matches" :key="match.id">
                      <tr v-if="isFutureMatch(match.time)" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA"></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB"></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>

                    <template v-if="hasGroup4(parsedMatchData)" v-for="match in parsedMatchData" :key="match.id">
                      <tr v-if="match.group == 'Group 4'" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" disabled></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" disabled></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>
                  </div>

                  <div v-else>
                    <template v-if="!hasGroup5(parsedMatchData)" v-for="match in matches" :key="match.id">
                      <tr v-if="isFutureMatch(match.time)" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA"></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB"></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>

                    <template v-if="hasGroup5(parsedMatchData)" v-for="match in parsedMatchData" :key="match.id">
                      <tr v-if="match.group == 'Group 5'" class="match-row">
                        <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                        <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" disabled></td>
                        <td class="td_3">:</td>
                        <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" disabled></td>
                        <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                      </tr>
                    </template>

                    <div v-if="activeTab == 'group5'">
                      <div v-if="hasActivities(userData.activities)">
                        <div class="table-title" style="margin-top: 30px;text-align: left;margin-bottom: 30px;">SPECIAL POINT</div>
                        <hr class="table-divider">
                        <div v-for="activity in parsedActivities" :key="activity.id" class="activity">

                          <p class="activity-name">{{ activity.ActivityName }}</p>

                          <div class="activity-item">
                            <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                              <input disabled type="radio" value="A" v-model="activity.FlageResult"> <label>Spain</label>
                            </div>

                            <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                              <input disabled type="radio" value="B" v-model="activity.FlageResult">
                              <label>England</label>
                            </div>
                            
                            <div v-if="activity.id == 1">
                              <div class="radio-item">
                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Goal</label>
                              </div>
                            </div>
                            <div v-if="activity.id == 2">
                              <div class="radio-item">
                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Substitution</label>
                              </div>
                            </div>
                            <div v-if="activity.id == 3">
                              <div class="radio-item">
                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Card</label>
                              </div>
                            </div>

                            
                            <div class="as"v-if="activity.id === 4">
                              <div class="radio-item">
                                <input disabled type="radio" value="A" v-model="activity.FlageResult">
                                <label> </label>
                              </div>
                              <div class="radio-item">
                                <input disabled class="small-input2" type="text" v-model="activity.TimeResult" >
                              </div>

                              <!-- <div style="margin-left: 10px;" class="radio-item">
                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Goal</label>
                              </div> -->

                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-else>
                        <div class="table-title" style="margin-top: 30px;text-align: left;margin-bottom: 30px;">SPECIAL POINT</div>
                        <hr class="table-divider">
                        <div v-for="activity in activities" :key="activity.id" class="activity">
                          <p class="activity-name">{{ activity.ActivityName }}</p>

                          <div class="activity-item">
                            <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                              <input  type="radio" value="A" v-model="activity.FlageResult"> <label>Spain</label>
                            </div>

                            <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                              <input  type="radio" value="B" v-model="activity.FlageResult">
                              <label>England</label>
                            </div>
                            
                            <div v-if="activity.id == 1">
                              <div class="radio-item">
                                <input  type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Goal</label>
                              </div>
                            </div>
                            <div v-if="activity.id == 2">
                              <div class="radio-item">
                                <input  type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Substitution</label>
                              </div>
                            </div>
                            <div v-if="activity.id == 3">
                              <div class="radio-item">
                                <input  type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Card</label>
                              </div>
                            </div>

                            
                            <div class="as"v-if="activity.id === 4">
                              <div class="radio-item">
                                <input  type="radio" value="A" v-model="activity.FlageResult">
                                <label> </label>
                              </div>
                              <div class="radio-item">
                                <input  class="small-input2" type="text" v-model="activity.TimeResult" >
                              </div>

                              <!-- <div style="margin-left: 10px;" class="radio-item">
                                <input  type="radio" value="C" v-model="activity.FlageResult">
                                <label>No Goal</label>
                              </div> -->

                            </div>
                          </div>


                        </div>
                      </div>
                    </div>

                    <div v-if="activeTab == 'group5'" class="submit-container">
                      <button v-if="!hasGroup5(parsedMatchData) && !hasActivities(userData.activities)" type="submit" class="submit-button" :disabled="formSubmitted">SUBMIT</button>
                    </div>
                  </div>
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
const matchesDataGroup2 = require('@/assets/json/matches2.json');
const matchesDataGroup3 = require('@/assets/json/matches3.json');
const matchesDataGroup4 = require('@/assets/json/matches4.json');
const matchesDataGroup5 = require('@/assets/json/matches5.json');

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      userData: null,
      parsedMatchData: [],
      parsedActivities: [],
      loading: false,
      totalRounds: 3, // Adjust this according to the total number of rounds
      activeTab: 'group1',
      matches: [], // Default to matchesDataGroup2
      formSubmitted: false,
      currentTab: 'Rules', // Default to Rules tab
      baseUrl: 'https://euro2024.egency.fun',
      flageLogin: false,
      activities: [
        {
          id: 0,
          ActivityName: "ทีมไหนเขี่ยลูกก่อน",
          FlageResult: "",
          TimeResult: "",
          Point: 5
        },
        {
          id: 1,
          ActivityName: "ทีมไหนยิงประตูได้ก่อน",
          FlageResult: "",
          TimeResult: "",
          Point: 5
        },
        {
          id: 2,
          ActivityName: "ทีมไหนเปลี่ยนตัวก่อน",
          FlageResult: "",
          TimeResult: "",
          Point: 5
        },
        {
          id: 3,
          ActivityName: "ทีมไหนโดนใบเหลืองก่อน",
          FlageResult: "",
          TimeResult: "",
          Point: 5
        },
        {
          id: 4,
          ActivityName: "ประตูแรกที่เกิดขึ้นนาทีที่เท่าไหร่",
          FlageResult: "",
          TimeResult: "",
          Point: 30
        }
      ]
    };
  },
  async created() {
    if (localStorage.hasOwnProperty('userData')) {
      await this.login();
    }
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        if (localStorage.hasOwnProperty('userData')) {
          const storedUserData = localStorage.getItem("userData");
          this.userData = JSON.parse(storedUserData);
          this.parsedMatchData = JSON.parse(this.userData.match_data);
          if(this.userData.activities){
            this.parsedActivities = JSON.parse(this.userData.activities);
          }
          console.log('this.userData', this.userData);
          console.log('this.parsedMatchData', this.parsedMatchData);
          console.log('this.parsedActivities', this.parsedActivities);
          this.loading = false;
          return;
        }

        const response = await axios.post(this.baseUrl + '/api/login.php', {
          username: this.username,
          password: this.password,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.data.error) {
          alert(response.data.message);
        } else {
          this.flageLogin = true;
          console.log('Login Success:', response.data);
          this.userData = response.data.data;
          this.parsedMatchData = JSON.parse(this.userData.match_data);
          if(this.userData.activities){
            this.parsedActivities = JSON.parse(this.userData.activities);
          }
          localStorage.setItem("userData", JSON.stringify(this.userData));
        }
      } catch (error) {
        console.error('Login Error:', error);
        alert('Error logging in. Please try again.');
      } finally {
        this.loading = false;
      }
    },
    changeTab(tab) {
      this.activeTab = tab;
      if (tab === 'group2') {
        this.matches = matchesDataGroup2;
      } else if (tab === 'group3') {
        this.matches = matchesDataGroup3;
        console.log('group3', this.matches)
      } else if (tab === 'group4') {
        this.matches = matchesDataGroup4;
      } else if (tab === 'group5') {
        this.matches = matchesDataGroup5;
      }
      this.formSubmitted = this.parsedMatchData.some(match => match.group === this.activeTab.replace('group', 'Group'));
    },
    isTodayInRange(startDate, endDate) {
      const today = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      return today >= start && today <= end;
    },
    hasGroup2(data) {
      return data.some(match => match.group === 'Group 2');
    },
    hasGroup3(data) {
      return data.some(match => match.group === 'Group 3');
    },
    hasGroup4(data) {
      return data.some(match => match.group === 'Group 4');
    },
    hasGroup5(data) {
      return data.some(match => match.group === 'Group 5');
    },
    hasActivities(data) {
      console.log('data',data);
      const zz =data && data.length > 0
      console.log(zz , 'rteeterer')
      return data && data.length > 0;
    },
    isFutureMatch(matchTime) {
      const [dayMonth, time] = matchTime.split(' ');
      const [day, month] = dayMonth.split('/');
      const [hours, minutes] = time.split(':');
      const matchDate = new Date();
      matchDate.setDate(day);
      matchDate.setMonth(month - 1);
      matchDate.setHours(hours);
      matchDate.setMinutes(minutes);
      matchDate.setSeconds(0);
      console.log('matchDate', matchDate)
      console.log('new Date()', new Date())

      return matchDate > new Date();
    },
    async submitForm() {
      if (window.confirm("Confirm ?")) {
        this.loading = true;

        this.matches = this.matches.map(match => {
          if (match.time) {
            match.time = match.time.replace(/\\\//g, '/');
          }
          return match;
        });

        const formData = {
          first_name: this.userData.first_name,
          last_name: this.userData.last_name || ' ',
          matches: JSON.stringify(this.matches),
          activities: JSON.stringify(this.activities),
          file_data: this.userData.file_path || null,
          selected_team_id: this.userData.selected_team_id || null,
          selected_team_name: this.userData.selected_team_name || null,
        };

        console.log('formData:', formData);
        try {
          const response = await axios.post(this.baseUrl + '/api/save_matches.php', formData, {
            headers: {
              'Content-Type': 'application/json',
            }
          });
          console.log('response:', response);
          if (response.data.status === 'success') {
            alert('ขอบคุณที่ร่วมสนุก ขอให้โชคดี รวยทรัพย์รับโชค เงินทองไหลมาเทมา');
            this.formSubmitted = true;
            let match = JSON.parse(this.userData.match_data);
            match.push(...this.matches);

            this.userData.match_data = JSON.stringify(match);
            this.userData.activities = formData.activities;
            localStorage.setItem("userData", JSON.stringify(this.userData));
            const storedUserData = localStorage.getItem("userData");
            this.userData = JSON.parse(storedUserData);
            this.parsedMatchData = JSON.parse(this.userData.match_data);
            if(this.userData.activities){
              this.parsedActivities = JSON.parse(this.userData.activities);
            }

          } else {
            alert(`บันทึกไม่สำเร็จ: ${response.data.message}`);
            console.error('Error:', response.data.message);
          }
        } catch (error) {
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
            alert(`บันทึกไม่สำเร็จ: ${response.data.message}`);
          } else if (error.request) {
            console.error('Error request:', error.request);
            alert('บันทึกไม่สำเร็จ: Network error. No response received.');
          } else {
            console.error('Error message:', error.message);
            alert(`บันทึกไม่สำเร็จ: ${error.message}`);
          }
        } finally {
          this.loading = false;
        }
      }
    }
  }
}
</script>


<style scoped>
.as{
display: flex;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  color: black;
  border-radius: 8px;
  text-align: center;
}

.header .main-title {
  font-size: 40px;
  font-weight: bold;
  color: black;
}

.header .subtitle {
  margin-top: -10px;
  font-weight: bold;
  color: gray;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  width: 100%;
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: black;
  text-align: left;
}

.form-group input[type="text"],
.form-group input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  background-color: #fff;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Prompt';
}

.login-button:hover {
  background-color: #333;
  font-family: 'Prompt';
}

.loading-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.tab-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ddd;
  color: black;
  font-size: 10px;
}

.tab-button.disabled {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ddd;
  color: rgb(207, 207, 207) !important;
}

.tab-button:disabled {
  color: rgb(207, 207, 207) !important;
  background-color: #ddd;
  cursor: not-allowed;
}

.tab-button.active {
  background-color: black;
  color: white;
}

.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.table-title {
  font-size: 20px;
  font-weight: bold;
}

.table-subtitle {
  font-size: 10px;
  font-weight: bold;
}

.table-divider {
  margin-top: -32px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.match-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.td_1,
.td_5 {
  padding: 5px 10px;
  text-align: center;
  flex: 1;
  font-size: 14px;
}

.td_2,
.td_3,
.td_4 {
  text-align: center;
  flex: 0 0 30px;
}

.small-input {
  width: 40px;
  height: 35px;
  text-align: center;
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.small-input2 {
  width: 36px;
  height: 35px;
  text-align: center;
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.submit-container {
  padding: 15px;
}

.submit-button {
  font-family: 'Prompt';
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #333;
}

.activity-item {
  display: flex;
  /* flex-direction: column; */
  margin-bottom: 10px;
}

.radio-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.radio-item label {
  margin-right: 10px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.registration-Rules {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 00px;
  margin-top: 20px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.tab-buttons span {
  /* padding: 10px; */
  margin: 0 10px;
  cursor: pointer;
}

.tab-buttons span.active {
  font-weight: bold;
}

.tab-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-header span {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.tab-header span.active {
  font-weight: bold;
}

.tabs-container {
  margin-top: -25px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.tabs-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tabs {
  display: inline-block;
}

.tab-button {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.tab-button.active {
  font-weight: bold;
}

.tab-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.activity {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}

.activity-name {
  text-align: left;
  margin-right: 100px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.time-input {
  width: 100%;
  max-width: 150px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: black;
  background-color: #fff;
  text-align: center;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');


</style>
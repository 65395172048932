<template>
    <div>
        <div class="full-width-image-wrapper" style="margin-top: 30px;">
            <img class="full-width-image" src="@/assets/leaderboard.png" alt="Leaderboard" />
        </div>
        <div class="container">
            <div v-if="loading" class="loading-modal">
                <div class="loading-content">
                    <div class="loading-spinner">loading...</div>
                </div>
            </div>
            <div class="table-container" v-if="!loading && allUsersData">
                <table>
                    <thead>
                        <tr>
                            <th style="font-size: 10px;">RANK</th>
                            <th style="font-size: 10px;">NAME</th>
                            <th>PTS</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in rankedUsers" :key="user.id">
                            <td>{{ user.rank }}</td>
                            <td @click="showMatchData(user.match_data, user.activities,user)">{{ user.first_name }}</td>
                            <td>{{ user.TotalScore }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="user-info">
                        <p>{{ selectedUser.first_name }}<br><b>{{ selectedUser.selected_team_name }}</b></p>
                        <p class="score">{{ selectedUser.TotalScore }} PTS</p>
                    </div>
                    <span class="close" @click="closeModal">&times;</span>
                </div>

                <div class="tabs">
                    <div class="tab-header-wrapper">
                        <div class="tab-header">
                            <button class="tab-button" @click="selectedTab = 1" :class="{ active: selectedTab === 1 }">QUALIFICATION</button>
                            <button class="tab-button" @click="selectedTab = 2" :class="{ active: selectedTab === 2 }">ROUND OF 16</button>
                            <button class="tab-button" @click="selectedTab = 3" :class="{ active: selectedTab === 3 }">QUARTER-FINALS</button>
                            <button class="tab-button" @click="selectedTab = 4" :class="{ active: selectedTab === 4 }">SEMI-FINALS</button>
                            <button class="tab-button" @click="selectedTab = 5" :class="{ active: selectedTab === 5 }">FINAL</button>
                        </div>
                    </div>

                    <div class="tab-content" v-show="selectedTab === 1">
                        <div class="table-container" v-for="round in [1, 2, 3]" :key="round">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                                <p style="font-size: 10px; font-weight: bold;">MATCH {{ round }} OF 3</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <table class="table2">
                                <tbody>
                                    <template v-for="match in selectedMatchData" :key="match.id">
                                        <tr v-if="match.round === round" class="match-row">
                                            <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span>
                                                {{ match.teamA }}
                                            </td>
                                            <td class="td_2">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionA" />
                                                </div>
                                            </td>
                                            <td class="td_3">:</td>
                                            <td class="td_4">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionB" />
                                                </div>
                                            </td>
                                            <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span>
                                                {{ match.teamB }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" v-show="selectedTab === 2">
                        <div class="table-container" v-for="round in [4]" :key="round">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <table class="table2">
                                <tbody>
                                    <template v-for="match in selectedMatchData" :key="match.id">
                                        <tr v-if="match.round === round" class="match-row">
                                            <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span>
                                                {{ match.teamA }}
                                            </td>
                                            <td class="td_2">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionA" />
                                                </div>
                                            </td>
                                            <td class="td_3">:</td>
                                            <td class="td_4">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionB" />
                                                </div>
                                            </td>
                                            <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span>
                                                {{ match.teamB }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" v-show="selectedTab === 3">
                        <div class="table-container" v-for="round in [5]" :key="round">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <table class="table2">
                                <tbody>
                                    <template v-for="match in selectedMatchData" :key="match.id">
                                        <tr v-if="match.round === round" class="match-row">
                                            <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span>
                                                {{ match.teamA }}
                                            </td>
                                            <td class="td_2">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionA" />
                                                </div>
                                            </td>
                                            <td class="td_3">:</td>
                                            <td class="td_4">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionB" />
                                                </div>
                                            </td>
                                            <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span>
                                                {{ match.teamB }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" v-show="selectedTab === 4">
                        <div class="table-container" v-for="round in [6]" :key="round">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <table class="table2">
                                <tbody>
                                    <template v-for="match in selectedMatchData" :key="match.id">
                                        <tr v-if="match.round === round" class="match-row">
                                            <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span>
                                                {{ match.teamA }}
                                            </td>
                                            <td class="td_2">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionA" />
                                                </div>
                                            </td>
                                            <td class="td_3">:</td>
                                            <td class="td_4">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionB" />
                                                </div>
                                            </td>
                                            <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span>
                                                {{ match.teamB }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="tab-content" v-show="selectedTab === 5">
                        <div class="table-container">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <table class="table2">
                                <tbody>
                                    <template v-for="match in selectedMatchData" :key="match.id">
                                        <tr v-if="match.group === 'Group 5'" class="match-row">
                                            <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span>
                                                {{ match.teamA }}
                                            </td>
                                            <td class="td_2">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionA" />
                                                </div>
                                            </td>
                                            <td class="td_3">:</td>
                                            <td class="td_4">
                                                <div class="input-container">
                                                    <input disabled class="small-input" type="number"
                                                        v-model="match.predictionB" />
                                                </div>
                                            </td>
                                            <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span>
                                                {{ match.teamB }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-container">
                            <div class="table-header">
                                <p style="font-size: 20px; font-weight: bold;">SPECIAL POINT</p>
                            </div>
                            <hr style="margin-top: -32px;" noshade>
                            <div v-if="hasActivities(selectedActivities)">
                                <div v-for="activity in selectedActivities" :key="activity.id" class="activity">
                                    <p class="activity-name">{{ activity.ActivityName }}</p>
                                    <div class="activity-item">
                                        <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                                            <input disabled type="radio" value="A" v-model="activity.FlageResult"> <label>Spain</label>
                                        </div>

                                        <div v-if="activity.id == 0 || activity.id == 1 || activity.id == 2 || activity.id == 3" class="radio-item">
                                            <input disabled type="radio" value="B" v-model="activity.FlageResult">
                                            <label>England</label>
                                        </div>
                                        
                                        <div v-if="activity.id == 1">
                                            <div class="radio-item">
                                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                                <label>No Goal</label>
                                            </div>
                                        </div>
                                        <div v-if="activity.id == 2">
                                            <div class="radio-item">
                                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                                <label>No Substitution</label>
                                            </div>
                                        </div>
                                        <div v-if="activity.id == 3">
                                            <div class="radio-item">
                                                <input disabled type="radio" value="C" v-model="activity.FlageResult">
                                                <label>No Card</label>
                                            </div>
                                        </div>
                                        
                                        <div class="as" v-if="activity.id === 4">
                                            <div class="radio-item">
                                                <input disabled type="radio" value="A" v-model="activity.FlageResult">
                                                <label> </label>
                                            </div>
                                            <div class="radio-item">
                                                <input disabled class="small-input2" type="text" v-model="activity.TimeResult" placeholder="นาทีที่เท่าไหร่">
                                            </div>
                                            <!-- <div style="margin-left: 10px;" class="radio-item">
                                                <input disabled type="radio" value="B" v-model="activity.FlageResult">
                                                <label>No Goal</label>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentTab: 'Rules',
            resultMatches: [],
            resultActivity:[],
            allUsersData: [],
            selectedUser: null,
            showModal: false,
            selectedTab: 1,
            selectedMatchData: [],
            selectedActivities: [],
            loading: false,
            TeamChame: {},
            useTest: false, // Flag to determine if test data should be used
            baseUrl: 'https://euro2024.egency.fun',
        };
    },
    async created() {
        await this.fetchResultMatches();
        await this.fetchResultCham();
        await this.fetchResultActivity();
        await this.fetchData();
        this.calculateScores();
    },
    computed: {
        rankedUsers() {
            // First, sort users by TotalScore (descending) and then by first_name (ascending)
            const sortedUsers = this.allUsersData.slice().sort((a, b) => {
                if (b.TotalScore === a.TotalScore) {
                    return a.first_name.localeCompare(b.first_name);
                }
                return b.TotalScore - a.TotalScore;
            });

            // Assign ranks
            let rank = 1;
            let previousScore = null;
            let rankAdjustment = 0;

            return sortedUsers.map((user, index, array) => {
                if (previousScore !== null && user.TotalScore < previousScore) {
                    rank += 1 + rankAdjustment;
                    rankAdjustment = 0;
                } else if (previousScore !== null && user.TotalScore === previousScore) {
                    rankAdjustment += 1;
                } else {
                    rankAdjustment = 0;
                }

                previousScore = user.TotalScore;
                return {
                    ...user,
                    rank
                };
            });
        }
    },
    methods: {
        async fetchResultMatches() {
            try {
                const response = await axios.get(this.baseUrl + '/json/resultMatchs.json');
                this.resultMatches = response.data;
            } catch (error) {
                console.error('Error fetching result matches data:', error);
            }
        },
        async fetchResultActivity() {
            try {
                const response = await axios.get(this.baseUrl + '/json/resultActivity.json');
                this.resultActivity = response.data;
            } catch (error) {
                console.error('Error fetching result matches data:', error);
            }
        },
        async fetchResultCham() {
            try {
                const response = await axios.get(this.baseUrl + '/json/resultCham.json');
                this.TeamChame = response.data;
            } catch (error) {
                console.error('Error fetching result cham data:', error);
            }
        },
        async fetchData() {
            this.loading = true;
            try {
                const response = this.useTest ? { data: testData } : await axios.get(this.baseUrl + '/api/get_users.php'); // Use test data if flag is set
                this.allUsersData = response.data.data;
                console.log('fetchResultMatches',this.allUsersData)
            } catch (error) {
                console.error('Error fetching user data:', error);
                alert('Error fetching data');
            } finally {
                this.loading = false;
            }
        },
        calculateScores() {
            for (let user of this.allUsersData) {
                let score = 0;
                const data = JSON.parse(user.match_data);
                const activity = JSON.parse(user.activities);
                for (let Match of this.resultMatches) {
                    const predictionMatch = data.find(
                        (m) => m.id == Match.id && m.predictionA !== '' && m.predictionB !== ''
                    );
                    if (!predictionMatch) continue;
                    if (
                        Match.predictionA === '' ||
                        Match.predictionB === '' ||
                        predictionMatch.predictionA === '' ||
                        predictionMatch.predictionB === ''
                    )
                        continue;

                    // win 3 score
                    if (
                        predictionMatch.predictionA == Match.predictionA &&
                        predictionMatch.predictionB == Match.predictionB
                    ) {
                        if(predictionMatch.group == 'Group 1'){
                            score += 3
                        } else if(predictionMatch.group =='Group 2' ){
                            score += 6
                        } else if(predictionMatch.group =='Group 3' ){
                            score += 9
                        } else if(predictionMatch.group =='Group 4' ){
                            score += 12
                        } else if(predictionMatch.group =='Group 5' ){
                            score += 15
                        }

                    }

                    let winOrLostOrDrawPrediction = 3;
                    let winOrLostOrDrawResult = 3;

                    winOrLostOrDrawPrediction =
                        predictionMatch.predictionA == predictionMatch.predictionB
                            ? 2
                            : predictionMatch.predictionA > predictionMatch.predictionB
                                ? 1
                                : predictionMatch.predictionA < predictionMatch.predictionB
                                    ? 0
                                    : 3;
                    winOrLostOrDrawResult =
                        Match.predictionA == Match.predictionB
                            ? 2
                            : Match.predictionA > Match.predictionB
                                ? 1
                                : Match.predictionA < Match.predictionB
                                    ? 0
                                    : 3;
                    // win 1 score
                    if (
                        winOrLostOrDrawPrediction == winOrLostOrDrawResult &&
                        (winOrLostOrDrawPrediction != 3 && winOrLostOrDrawResult != 3)
                    ) {
                        if(predictionMatch.group == 'Group 1'){
                            score += 1
                        } else if(predictionMatch.group =='Group 2' ){
                            score += 2
                        } else if(predictionMatch.group =='Group 3' ){
                            score += 3
                        } else if(predictionMatch.group =='Group 4' ){
                            score += 4
                        } else if(predictionMatch.group =='Group 5' ){
                            score += 5
                        }
                    }
                }
                // win 12 score
                if (user.selected_team_name == this.TeamChame.name) {
                    score += 12;
                }
                // if(user.first_name == "A+"){
                    console.log('user',user);
                    console.log('user.first_name', user.first_name)
                    console.log('activity',activity)
                    console.log('result activity',this.resultActivity)

                    if (activity) {
                        console.log('if activity');
                        const resultActivity = this.resultActivity;

                        for (let i = 0; i <= 3; i++) {
                            if (activity[i] && (activity[i].id === i)) {
                                console.log(`if activity ${i}`);
                                if (activity[i].FlageResult === resultActivity[i].FlageResult) {
                                    console.log('id +5');
                                    score += 5;
                                } else {
                                    console.log(`id= ${activity[i].id} 0`);
                                }
                            }
                        }

                        if (activity[4] && activity[4].id === 4) {
                            console.log('if activity 4');
                            // รอปรับปรุง code 
                            if ((activity[4].FlageResult === resultActivity[4].FlageResult)&&(activity[4].TimeResult == resultActivity[4].TimeResult)) {
                                console.log('id +30');
                                score += 30;
                                }else{
                                console.log(`id= ${activity[4].id} 0`);
                                }
                        }
                    }
                // }
                user.TotalScore = score;

            }
            this.allUsersData.sort((a, b) => b.TotalScore - a.TotalScore);
        },
        useTestData() {
            this.useTest = true;
            this.fetchData().then(() => this.calculateScores());
        },
        showMatchData(matchData, activities,user) {
            console.log('user', user)
            if (matchData) {
                try {
                    this.selectedMatchData = JSON.parse(matchData);
                    this.selectedActivities = activities ? JSON.parse(activities) : [];
                    console.log('this.selectedActivities', this.selectedActivities)
                    this.selectedUser = this.allUsersData.find(user => user.match_data === matchData);
                    this.showModal = true;
                } catch (error) {
                    console.error("Error parsing JSON data:", error);
                }
            } else {
                console.error("Match data or activities are undefined", { matchData, activities });
            }
        },
        closeModal() {
            this.showModal = false;
            this.selectedMatchData = [];
            this.selectedActivities = [];
        },
        hasActivities(data) {
            console.log(data)
            return data && data.length > 0;
        }
    },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

body {
    background-color: #fff;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.header {
    text-align: center;
}

.header h1 {
    font-size: 2em;
    font-weight: bold;
}

.header p {
    margin: 0;
}

.rules {
    background-color: #000;
    color: #fff;
    padding: 20px;
    margin: 0px 0;
    border-radius: 8px;
}

.rules h2 {
    margin: 0 0 10px 0;
}

.rules ul {
    list-style-type: none;
    padding: 0;
}

.rules ul li {
    margin: 5px 0;
}

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    box-shadow: 0 0 0px rgba(255, 255, 255, 0.1);
}

.full-width-image-wrapper {
    width: 100vw;
    background-color: #fff;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

.full-width-image {
    width: 100%;
    height: auto;
    display: block;
}

.registration-Rules {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
    margin: 0 auto 0px;
    margin-bottom: 00px;
    margin-top: 20px;
}

.form-group {
    margin-bottom: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.form-group input[type='text'],
.form-group input[type='file'] {
    width: calc(100% - 20px);
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.table-container {
    margin-bottom: 0px;
    overflow-x: auto;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

.table2 {
    margin: auto;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    border: 1px solid rgba(204, 204, 204, 0);
    overflow: hidden;
}

th,
td {
    padding: 8px;
    border: none;
}

th {
    background-color: white;
    border-bottom: 1px solid #ddd;
}

td {
    text-align: center;
}

td:nth-child(2),
th:nth-child(2) {
    text-align: left;
}

tbody tr:nth-child(odd) {
    color: #000000;
}

tbody tr:nth-child(even) {
    color: #000000;
    background-color: #EEEEEE;
}

button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 90%;
    max-width: 600px;
    overflow-y: auto;
    max-height: 90vh;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.user-info p {
    margin: 0;
}

.user-info .score {
    font-size: 20px;
    font-weight: bold;
}

.close {
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    background-color: black;
    color: white;
    text-decoration: none;
    cursor: pointer;
}

.modal table {
    background-color: white;
}

.modal tbody tr:nth-child(even) {
    background-color: #ffffff;
}

@media (max-width: 600px) {
    .modal-content {
        width: 95%;
        max-width: 95%;
    }

    table,
    th,
    td {
        font-size: 1.0em;
    }

    th,
    td {
        padding: 6px;
    }
}

.match-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.td_1 {
    padding-right: 10px;
    text-align: right;
    flex: 1;
    font-size: 14px;
}

.td_2,
.td_3,
.td_4 {
    text-align: center;
    flex: 0 0 30px;
}

.td_4 {
    text-align: center;
    flex: 0 0 30px;
    width: 20px;
}

.td_5 {
    padding-left: 10px;
    text-align: left;
    flex: 1;
    font-size: 14px;
}

.input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.small-input2 {
  width: 36px;
  height: 35px;
  text-align: center;
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.small-input {
    width: 40px;
    height: 35px;
    text-align: center;
    border: none;
    border: solid 1px #CCC;
    border-radius: 5px;
    font-family: 'Prompt';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.registration-image {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
    margin: 0 auto 0px;
    margin-bottom: 20px;
}

.table-container {
    /* margin-bottom: 20px; */
    overflow-x: auto;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.registration-Rules {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
    margin: 0 auto 0px;
    margin-bottom: 00px;
    margin-top: 20px;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.tab-buttons span {
    margin: 0 10px;
    cursor: pointer;
}

.tab-buttons span.active {
    font-weight: bold;
}
.tab-button {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ddd;
  color: black;
  font-size: 10px;
}
.tab-button.disabled {
  padding: 10px 20px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ddd;
  color: rgb(207, 207, 207) !important;
}

.tab-button:disabled {
  color: rgb(207, 207, 207) !important;
  background-color: #ddd;
  cursor: not-allowed;
}

.tab-header-wrapper {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tab-header-wrapper::-webkit-scrollbar {
    display: none;
}

.tab-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-top: 20px;
    width: max-content;
}

.tab-header button {
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    background-color: #ccc;
    color: black;
    border: none;
}

.tab-header button.active {
    background-color: #000;
    color: white;
}

.activity {
    padding: 5px 0;
}

.activity-name {
    font-weight: bold;
}

.activity-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.radio-item {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.radio-item label {
    margin-left: 5px;
}

.as {
    display: flex;
    align-items: center;
}

.time-input {
    width: 100%;
    max-width: 150px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    color: black;
    background-color: #fff;
    text-align: center;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Ero from "@/components/Ero.vue";
import UserDataTable from "@/components/UserDataTable.vue";
import Leaderboard from "@/components/Leaderboard.vue";
import Rules from "@/components/Rules.vue";
import jojoAdminVip from "@/components/Admin.vue";
import Login from "@/components/Login.vue";
import App from "../App.vue";

const routes = [
  {
    path: "/",
    // path: '/test/',
    name: "App",
    component: App,
  },
  // {
  //   path: '/leaderboard',
  //   name: 'UserDataTable',
  //   component: UserDataTable,
  // },
  {
    path: "/leaderboard",
    name: "Leaderboard",
    component: Leaderboard,
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
  },
  {
    path: "/jojoadminvip",
    // path: '/test/jojoadminvip',
    name: "jojoadminVIP",
    component: jojoAdminVip,
  },
  {
    path: "/login",
    // path: '/test/login',
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <div>
    <div class="container">
      <div class="tab-buttons">
        <span :class="{ active: currentTab === 'Rules' }" @click="currentTab = 'Rules'">Rules</span>
        <span>|</span>
        <span :class="{ active: currentTab === 'Leaderboard' }" @click="currentTab = 'Leaderboard'">Leaderboard</span>
      </div>
      <div class="header">
        <p style="font-size: 40px; font-weight: bold;">EURO 2024</p>
        <p style="margin-top: -10px; font-weight: bold;">TRUST YOUR GUTS, NOT THE FACTS</p>
      </div>

    </div>

    <div class="tab-content">
      <div v-if="currentTab === 'Rules'">
        <img class="registration-Rules" src="@/assets/Txt-Rules.png" alt="Registration Rules" />
      </div>
      <div v-if="currentTab === 'Leaderboard'">
        <div class="full-width-image-wrapper">
          <img class="full-width-image" src="@/assets/leaderboard.png" alt="Leaderboard" />
        </div>
        <div class="container">
          <div v-if="loading" class="loading-modal">
            <div class="loading-content">
              <div class="loading-spinner">loading...</div>
            </div>
          </div>
          <div class="table-container" v-if="!loading && allUsersData">
            <table>
              <thead>
                <tr>
                  <th style="font-size: 10px;">RANK</th>
                  <th style="font-size: 10px;">NAME</th>
                  <th>PTS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(user, index) in rankedUsers" :key="user.id">
                  <td>{{ user.rank }}</td>
                  <td @click="showMatchData(user.match_data)">{{ user.first_name }}</td>
                  <td>{{ user.TotalScore }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="user-info">
            <p>{{ selectedUser.first_name }}<br><b>{{ selectedUser.selected_team_name }}</b></p>
            <p class="score">{{ selectedUser.TotalScore }} PTS</p>
          </div>
          <span class="close" @click="closeModal">&times;</span>
        </div>

        <div class="tabs">
          <div class="tab-header">
            <button class="tab-button" @click="selectedTab = 1" :class="{ active: selectedTab === 1 }">QUALIFICATION</button>
            <button class="tab-button" @click="selectedTab = 2" :class="{ active: selectedTab === 2 }">ROUND OF 16 FIXTURES</button>
            <!-- <button class="tab-button" @click="selectedTab = 3" :class="{ active: selectedTab === 3 }">Round 3</button> -->
            <!-- <button class="tab-button" @click="selectedTab = 3" :class="{ active: selectedTab === 3 }">Round 4</button> -->
            <!-- <button class="tab-button" @click="selectedTab = 3" :class="{ active: selectedTab === 3 }">Round 5</button> -->
          </div>

          <div class="tab-content" v-show="selectedTab === 1">
            <div class="table-container" v-for="round in [1, 2, 3]" :key="round">
              <div class="table-header">
                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                <p style="font-size: 10px; font-weight: bold;">MATCH {{ round }} OF 3</p>
              </div>
              <hr style="margin-top: -32px;" noshade>
              <table class="table2">
                <tbody>
                  <template v-for="match in selectedMatchData" :key="match.id">
                    <tr v-if="match.round === round" class="match-row">
                      <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}
                      </td>
                      <td class="td_2">
                        <div class="input-container">
                          <input disabled class="small-input" type="number" v-model="match.predictionA" />
                        </div>
                      </td>
                      <td class="td_3">:</td>
                      <td class="td_4">
                        <div class="input-container">
                          <input disabled class="small-input" type="number" v-model="match.predictionB" />
                        </div>
                      </td>
                      <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="tab-content" v-show="selectedTab === 2">
            <div class="table-container" v-for="round in [4]" :key="round">
              <div class="table-header">
                <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
                <p style="font-size: 10px; font-weight: bold;">MATCH {{ round }}</p>
              </div>
              <hr style="margin-top: -32px;" noshade>
              <table class="table2">
                <tbody>
                  <template v-for="match in selectedMatchData" :key="match.id">
                    <tr v-if="match.round === round" class="match-row">
                      <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}
                      </td>
                      <td class="td_2">
                        <div class="input-container">
                          <input disabled class="small-input" type="number" v-model="match.predictionA" />
                        </div>
                      </td>
                      <td class="td_3">:</td>
                      <td class="td_4">
                        <div class="input-container">
                          <input disabled class="small-input" type="number" v-model="match.predictionB" />
                        </div>
                      </td>
                      <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="tab-content" v-show="selectedTab === 3">
            <!-- Additional content for Tab3 can be added here -->
            <p>Content for Tab 3</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      currentTab: 'Rules',
      resultMatches: [],
      allUsersData: [],
      selectedUser: null,
      showModal: false,
      selectedTab: 1,
      selectedMatchData: [],
      loading: false,
      TeamChame: {},
      useTest: false, // Flag to determine if test data should be used
      // baseUrl: 'https://euro2024.egency.fun/test',
      baseUrl: 'https://euro2024.egency.fun',
    };
  },
  async created() {
    await this.fetchResultMatches();
    await this.fetchResultCham();
    await this.fetchData();
    this.calculateScores();
  },
  computed: {
    rankedUsers() {
      // First, sort users by TotalScore (descending) and then by first_name (ascending)
      const sortedUsers = this.allUsersData.slice().sort((a, b) => {
        if (b.TotalScore === a.TotalScore) {
          return a.first_name.localeCompare(b.first_name);
        }
        return b.TotalScore - a.TotalScore;
      });

      // Assign ranks
      let rank = 1;
      let previousScore = null;
      let rankAdjustment = 0;

      return sortedUsers.map((user, index, array) => {
        if (previousScore !== null && user.TotalScore < previousScore) {
          rank += 1 + rankAdjustment;
          rankAdjustment = 0;
        } else if (previousScore !== null && user.TotalScore === previousScore) {
          rankAdjustment += 1;
        } else {
          rankAdjustment = 0;
        }

        previousScore = user.TotalScore;
        return {
          ...user,
          rank
        };
      });
    }
  },
  methods: {
    async fetchResultMatches() {
      try {
        const response = await axios.get(this.baseUrl + '/json/resultMatchs.json');
        this.resultMatches = response.data;
      } catch (error) {
        console.error('Error fetching result matches data:', error);
      }
    },
    async fetchResultCham() {
      try {
        const response = await axios.get(this.baseUrl + '/json/resultCham.json');
        this.TeamChame = response.data;
      } catch (error) {
        console.error('Error fetching result cham data:', error);
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const response = this.useTest ? { data: testData } : await axios.get(this.baseUrl + '/api/get_users.php'); // Use test data if flag is set
        this.allUsersData = response.data.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
        alert('Error fetching data');
      } finally {
        this.loading = false;
      }
    },
    calculateScores() {
      for (let user of this.allUsersData) {
        let score = 0;
        const data = JSON.parse(user.match_data);
        for (let Match of this.resultMatches) {
          const predictionMatch = data.find(
            (m) => m.id == Match.id && m.predictionA !== '' && m.predictionB !== ''
          );
          if (!predictionMatch) continue;
          if (
            Match.predictionA === '' ||
            Match.predictionB === '' ||
            predictionMatch.predictionA === '' ||
            predictionMatch.predictionB === ''
          )
            continue;

          // win 3 score
          if (
            predictionMatch.predictionA == Match.predictionA &&
            predictionMatch.predictionB == Match.predictionB
          ) {
            predictionMatch.group !== 'Group 2' ? score += 3 : score += 6;
          }

          let winOrLostOrDrawPrediction = 3;
          let winOrLostOrDrawResult = 3;

          winOrLostOrDrawPrediction =
            predictionMatch.predictionA == predictionMatch.predictionB
              ? 2
              : predictionMatch.predictionA > predictionMatch.predictionB
                ? 1
                : predictionMatch.predictionA < predictionMatch.predictionB
                  ? 0
                  : 3;
          winOrLostOrDrawResult =
            Match.predictionA == Match.predictionB
              ? 2
              : Match.predictionA > Match.predictionB
                ? 1
                : Match.predictionA < Match.predictionB
                  ? 0
                  : 3;
          // win 1 score
          if (user.first_name == 'A+' && predictionMatch.group == 'Group 2') {
            debugger
          }
          console.log(user.first_name);
          if (
            winOrLostOrDrawPrediction == winOrLostOrDrawResult &&
            (winOrLostOrDrawPrediction != 3 && winOrLostOrDrawResult != 3)
          ) {
            predictionMatch.group !== 'Group 2' ? score += 1 : score += 2;

          }
        }
        // win 12 score
        if (user.selected_team_name == this.TeamChame.name) {
          score += 12;
        }

        user.TotalScore = score;
      }
      this.allUsersData.sort((a, b) => b.TotalScore - a.TotalScore);
    },
    useTestData() {
      this.useTest = true;
      this.fetchData().then(() => this.calculateScores());
    },
    showMatchData(matchData) {
      this.selectedMatchData = JSON.parse(matchData);
      this.selectedUser = this.allUsersData.find(user => user.match_data === matchData);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedMatchData = [];
    },
  },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

body {
  background-color: #fff;
  /* Set background color to white */
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  text-align: center;
}

.header h1 {
  font-size: 2em;
  font-weight: bold;
}

.header p {
  margin: 0;
}

.rules {
  background-color: #000;
  color: #fff;
  padding: 20px;
  margin: 0px 0;
  border-radius: 8px;
}

.rules h2 {
  margin: 0 0 10px 0;
}

.rules ul {
  list-style-type: none;
  padding: 0;
}

.rules ul li {
  margin: 5px 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 0 0px rgba(255, 255, 255, 0.1);
}

.full-width-image-wrapper {
  width: 100vw;
  /* Full viewport width */
  background-color: #fff;
  /* White background color */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -20px;
}

.full-width-image {
  width: 100%;
  /* Full width */
  height: auto;
  /* Auto height to maintain aspect ratio */
  display: block;
}

.registration-Rules {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 00px;
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input[type='text'],
.form-group input[type='file'] {
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.table-container {
  margin-bottom: 0px;
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  /* Remove table border */
}

.table2 {
  margin: auto;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid rgba(204, 204, 204, 0);
  overflow: hidden;
}

th,
td {
  padding: 8px;
  border: none;
  /* Remove cell borders */
}

th {
  background-color: white;
  /* Set header background to white */
  border-bottom: 1px solid #ddd;
  /* Add bottom border to header */
}

td {
  text-align: center;
}

td:nth-child(2),
/* Name column */
th:nth-child(2) {
  text-align: left;
  /* Align text to the left for name column */
}

tbody tr:nth-child(odd) {
  color: #000000;
  /* Black font color for odd rows */
}

tbody tr:nth-child(even) {
  color: #000000;
  /* Dark gray font color for even rows */
  background-color: #EEEEEE;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.modal {
  display: block;
  /* ทำให้โมดาลแสดงผลเสมอเมื่อ showModal เป็น true */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 600px;
  overflow-y: auto;
  max-height: 90vh;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.user-info p {
  margin: 0;
}

.user-info .score {
  font-size: 20px;
  font-weight: bold;
}

.close {
  background-color: black;
  /* Background color is black */
  color: white;
  /* Font color is white */
  border: none;
  border-radius: 50%;
  /* Make it a circle */
  width: 40px;
  /* Width of the circle */
  height: 40px;
  /* Height of the circle */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  /* Increase font size */
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  background-color: black;
  /* Maintain the background color on hover/focus */
  color: white;
  /* Maintain the font color on hover/focus */
  text-decoration: none;
  cursor: pointer;
}

.modal table {
  background-color: white;
  /* Set table background to white */
}

.modal tbody tr:nth-child(even) {
  background-color: #ffffff;
  /* White background for even rows in the modal */
}

@media (max-width: 600px) {
  .modal-content {
    width: 95%;
    max-width: 95%;
  }

  table,
  th,
  td {
    font-size: 1.0em;
  }

  th,
  td {
    padding: 6px;
  }
}

.match-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.td_1 {
  padding-right: 10px;
  text-align: right;
  flex: 1;
  font-size: 14px;
}

.td_2,
.td_3,
.td_4 {
  text-align: center;
  flex: 0 0 30px;
}

.td_4 {
  text-align: center;
  flex: 0 0 30px;
  width: 20px;
  /* Make input fields more compact */
}

.td_5 {
  padding-left: 10px;
  text-align: left;
  flex: 1;
  font-size: 14px;
}

.input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* Ensure the container takes the full height */
}

.small-input {
  width: 30px;
  height: 35px;
  text-align: center;
  /* Center-align text horizontally */
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  /* Remove any default padding */
  box-sizing: border-box;
  /* Ensure padding and border are included in the element's total width and height */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.registration-image {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 20px;
}

.table-container {
  margin-bottom: 20px;
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.registration-Rules {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 00px;
  margin-top: 20px;
}

.tab-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.tab-buttons span {
  /* padding: 10px; */
  margin: 0 10px;
  cursor: pointer;
}

.tab-buttons span.active {
  font-weight: bold;
}

.tab-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.tab-header button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #000;
}

.tab-header span.active {
  font-weight: bold;
}
</style>

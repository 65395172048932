<template>
  <div>
    <div class="container">
      <div class="header">
        <p style="font-size: 40px; font-weight: bold;">EURO 2024</p>
        <p style="margin-top: -10px; font-weight: bold;">TRUST YOUR GUTS, NOT THE FACTS</p>
      </div>
      <img class="registration-Rules" src="@/assets/Txt-Rules.png" alt="Registration" />
    </div>
    <div class="registration-image-wrapper">
      <img class="registration-image" src="@/assets/Txt-Register.png" alt="Registration" />
    </div>
    <div class="container">
      <form v-if="!formSubmitted" @submit.prevent="submitForm">
        <div class="form-group">
          <label for="firstName">Name</label>
          <input type="text" v-model="firstName" id="firstName" placeholder="Name" />
        </div>
        <div class="form-group">
          <label for="file">Upload Slip</label>
          <input type="file" @change="onFileChange" id="file" />
        </div>

        <div class="form-group">
          <label for="champion">Select Champion Team</label>
          <select v-model="selectedTeam" id="champion">
            <option v-for="team in teams" :key="team.id" :value="team.name">{{ team.name }}</option>
          </select>
        </div>

        <div class="table-container">
          <div class="table-header">
            <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
            <p style="font-size: 10px; font-weight: bold;">MATCH 1 OF 3</p>
          </div>
          <hr style="margin-top: -32px;" noshade>
          <table>
            <tbody>
              <template v-for="match in matches" :key="match.id">
                <tr v-if="match.round === 1 && isFutureMatch(match.time)" class="match-row">
                  <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                  <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" /></td>
                  <td class="td_3">:</td>
                  <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" /></td>
                  <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="table-container">
          <div class="table-header">
            <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
            <p style="font-size: 10px; font-weight: bold;">MATCH 2 OF 3</p>
          </div>
          <hr style="margin-top: -32px;" noshade>
          <table>
            <tbody>
              <template v-for="match in matches" :key="match.id">
                <tr v-if="match.round === 2 && isFutureMatch(match.time)" class="match-row">
                  <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                  <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" /></td>
                  <td class="td_3">:</td>
                  <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" /></td>
                  <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>

        <div class="table-container">
          <div class="table-header">
            <p style="font-size: 20px; font-weight: bold;">SCORE PREDICTION</p>
            <p style="font-size: 10px; font-weight: bold;">MATCH 3 OF 3</p>
          </div>
          <hr style="margin-top: -32px;" noshade>
          <table>
            <tbody>
              <template v-for="match in matches" :key="match.id">
                <tr v-if="match.round === 3 && isFutureMatch(match.time)" class="match-row">
                  <td class="td_1"><span :class="`flag-icon flag-icon-${match.flagA}`"></span> {{ match.teamA }}</td>
                  <td class="td_2"><input class="small-input" type="number" v-model="match.predictionA" /></td>
                  <td class="td_3">:</td>
                  <td class="td_4"><input class="small-input" type="number" v-model="match.predictionB" /></td>
                  <td class="td_5"><span :class="`flag-icon flag-icon-${match.flagB}`"></span> {{ match.teamB }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div style="padding: 15px;">
          <button type="submit">SUBMIT</button>
        </div>
      </form>

      <div v-if="allUsersData && !loading">
        <h2>SUMMARY OF MATCH RESULTS</h2>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>FirstName</th>
                <th>LastName</th>
                <th>Match Data</th>
                <th>Img</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="data in allUsersData" :key="data.id">
                <tr>
                  <td>{{ data.id }}</td>
                  <td>{{ data.first_name }}</td>
                  <td>{{ data.last_name }}</td>
                  <td>
                    <button @click="showMatchData(data.match_data)">View</button>
                  </td>
                  <td>
                    <img :src="data.file_path" alt="รูปภาพ" style="width: 100px; height: auto;" />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <span class="close" @click="closeModal">&times;</span>
          <h2>information</h2>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th class="team-column">Team A</th>
                  <th></th>
                  <th class="team-column">Team B</th>
                  <th></th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="match in selectedMatchData" :key="match.id">
                  <td class="team-column">{{ match.teamA }}</td>
                  <td class="small-input">{{ match.predictionA }}</td>
                  <td class="small-input">{{ match.teamB }}</td>
                  <td class="team-column">{{ match.predictionB }}</td>
                  <td>{{ match.time }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="loading" class="loading-modal">
        <div class="loading-content">
          <div class="loading-spinner">loading...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const matchesData = require('@/assets/json/matches1.json');
const TeamChameData = require('@/assets/json/TeamCham.json');

export default {
  data() {
    return {
      firstName: '',
      lastName: '',
      file: null,
      formSubmitted: false,
      matches: matchesData,
      allUsersData: null,
      showModal: false,
      selectedMatchData: [],
      loading: false,
      selectedTeam: '',
      teams: TeamChameData,
      baseUrl: 'https://euro2024.egency.fun',
    };
  },
  computed: {
    selectedTeamId() {
      const selectedTeam = this.teams.find(team => team.name === this.selectedTeam);
      return selectedTeam ? selectedTeam.id : null;
    }
  },
  created() {
    this.loadFormData();
    const member = localStorage.getItem("keyMember");
    if (member) {
      window.location = '/leaderboard';
    }
  },
  methods: {
    isFutureMatch(matchTime) {
      const [dayMonth, time] = matchTime.split(' ');
      const [day, month] = dayMonth.split('/');
      const [hours, minutes] = time.split(':');
      const matchDate = new Date();
      matchDate.setDate(day);
      matchDate.setMonth(month - 1);
      matchDate.setHours(hours);
      matchDate.setMinutes(minutes);
      matchDate.setSeconds(0);

      return matchDate > new Date();
    },
    showMatchData(matchData) {
      this.selectedMatchData = JSON.parse(matchData);
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedMatchData = [];
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        this.file = null;
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height *= maxWidth / width));
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width *= maxHeight / height));
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.fillStyle = '#FFFFFF';
          ctx.fillRect(0, 0, width, height);
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onloadend = () => {
              this.file = reader.result;
            };
          }, 'image/jpeg', 0.7);
        };
      };
    },
    async submitForm() {
      if (window.confirm("Confirm ?")) {
        if (this.firstName == "") {
          alert('Forgot your name ?')
          return
        }
        this.loading = true;
        const formData = {
          first_name: this.firstName,
          last_name: this.lastName,
          matches: JSON.stringify(this.matches),
          file_data: this.file ?? "",
          selected_team_id: this.selectedTeamId,
          selected_team_name: this.selectedTeam,
        };

        console.log('formData:', formData);

        try {
          const response = await axios.post(this.baseUrl + '/api/save_matches.php', formData, {
            headers: {
              'Content-Type': 'application/json',
            }
          });

          console.log('response:', response);

          if (response.data.status === 'success') {
            alert('ขอบคุณที่ร่วมสนุก ขอให้โชคดี รวยทรัพย์รับโชค เงินทองไหลมาเทมา');
            localStorage.setItem("keyMember", true);
            window.location = '/leaderboard';
          } else {
            alert(`บันทึกไม่สำเร็จ: ${response.data.message}`);
            console.error('Error:', response.data.message);
          }
        } catch (error) {
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
            alert(`บันทึกไม่สำเร็จ: ${error.response.data.message}`);
          } else if (error.request) {
            console.error('Error request:', error.request);
            alert('บันทึกไม่สำเร็จ: Network error. No response received.');
          } else {
            console.error('Error message:', error.message);
            alert(`บันทึกไม่สำเร็จ: ${error.message}`);
          }
        } finally {
          this.loading = false;
        }
      }
    },
    loadFormData() {
      const savedData = localStorage.getItem('formData');
      if (savedData) {
        try {
          this.allUsersData = JSON.parse(savedData);
          this.formSubmitted = true;
        } catch (error) {
          console.error('Error parsing JSON from localStorage:', error);
        }
      }
    },
    clearFormData() {
      console.log('clearFormData');
      localStorage.removeItem('formData');
      this.allUsersData = null;
      this.firstName = '';
      this.lastName = '';
      this.file = null;
      this.matches = matchesData;
      this.formSubmitted = false;
    },
    testForm() {
      this.firstName = 'Test';
      this.lastName = 'User';
      this.matches.forEach((match) => {
        match.predictionA = Math.floor(Math.random() * 5);
        match.predictionB = Math.floor(Math.random() * 5);
      });
      const imgPath = require('@/assets/Logo-Mazda.svg');
      fetch(imgPath)
        .then((res) => res.blob())
        .then((blob) => {
          this.file = new File([blob], 'Logo-Mazda.svg', { type: 'image/svg+xml' });
          this.submitForm();
        });
    },
  },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/css/flag-icon.min.css');

body {
  background-color: #fff;
  /* Set background color to white */
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

.header {
  text-align: center;
}

.header h1 {
  font-size: 2em;
  font-weight: bold;
}

.header p {
  margin: 0;
}

.rules {
  background-color: #000;
  color: #fff;
  padding: 20px;
  margin: 0px 0;
  border-radius: 8px;
}

.rules h2 {
  margin: 0 0 10px 0;
}

.rules ul {
  list-style-type: none;
  padding: 0;
}

.rules ul li {
  margin: 5px 0;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  box-shadow: 0 0 0px rgba(255, 255, 255, 0.1);
}

.registration-image-wrapper {
  width: 100vw;
  /* Full viewport width */
  background-color: #fff;
  /* White background color */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: -20px;
}

.registration-image {
  width: 100%;
  /* Full width */
  height: auto;
  /* Auto height to maintain aspect ratio */
  display: block;
}

.registration-Rules {
  width: 100%;
  height: auto;
  max-width: 500px;
  display: block;
  margin: 0 auto 0px;
  margin-bottom: 00px;
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

/* ซ่อนลูกศรในกล่อง input text และ file */
input[type="text"]::-webkit-outer-spin-button,
input[type="text"]::-webkit-inner-spin-button,
input[type="file"]::-webkit-outer-spin-button,
input[type="file"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="text"],
input[type="file"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

/* เพิ่มสไตล์สำหรับกล่องดร็อปดาว */
.form-group select {
  width: calc(100% - 20px);
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Prompt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>') no-repeat right 10px center;
  background-size: 24px 24px;
}

.table-container {
  margin-bottom: 0px;
  overflow-x: auto;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

table {
  margin: auto;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid rgba(204, 204, 204, 0);
  overflow: hidden;
}

.match-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.td_1 {
  padding-right: 10px;
  text-align: right;
  flex: 1;
  font-size: 14px;
}

.td_2,
.td_3,
.td_4 {
  text-align: center;
  flex: 0 0 30px;
}

.td_5 {
  padding-left: 10px;
  text-align: left;
  flex: 1;
  font-size: 14px;
}

.divider-row {
  background-color: #000;
  color: #fff;
  text-align: left;
  font-weight: bold;
}

button {
  height: 40px;
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #444;
}

.small-input {
  width: 30px;
  height: 35px;
  text-align: center;
  border: none;
  border: solid 1px #CCC;
  border-radius: 5px;
  font-family: 'Prompt';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  /* Optional: Remove default spacing */
}

@media (max-width: 600px) {
  .header h1 {
    font-size: 1.5em;
  }

  .form-group input[type='text'],
  .form-group input[type='file'],
  .form-group select {
    width: calc(100% - 10px);
    padding: 12px;
  }

  button {
    padding: 10px;
    font-size: 14px;
  }
}
</style>

<template>
    <div>
        <div class="imgPad" v-if="currentTab === 'Rules'">
            <img class="registration-Rules" src="@/assets/Txt-Rules.png" alt="Registration Rules" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentTab: 'Rules',
        };
    },
    async created() {
    },
    computed: {
    },
    methods: {
    },
};
</script>

<style scoped>
.registration-Rules {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
    margin-bottom: 30px;
}
.imgPad{
    padding: 0 20px 0 20px;
}

</style>
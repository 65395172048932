import { createStore } from 'vuex';

const store = createStore({
  state: {
    message: 'Hello Vue!'
  },
  mutations: {},
  actions: {},
  modules: {}
});

export default store;